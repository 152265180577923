.clickable-card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  max-width: 420px;
  margin: 0 auto;
}

.clickable-card {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border: 2px solid #e8e8e8;
  border-radius: 8px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 16px;
}

.clickable-card:hover {
  border-color: #1890ff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.clickable-card.selected {
  border-color: #1890ff;
  background-color: #e6f7ff;
}

.clickable-card-content {
  font-size: 16px;
  color: #333;
}

/* Rotating background colors for cards */
.clickable-card.card-1 { background-color: #FFB3BA; }
.clickable-card.card-2 { background-color: #BAFFC9; }
.clickable-card.card-3 { background-color: #BAE1FF; }
.clickable-card.card-4 { background-color: #FFFFBA; }

/* Ensure text is readable on all background colors */
.clickable-card {
  color: #000;
}

/* Responsive design for smaller screens */
@media (max-width: 480px) {
  .clickable-card-container {
    grid-template-columns: 1fr;
    max-width: 220px;
  }

  .clickable-card {
    height: 150px;
  }

  .clickable-card-content {
    font-size: 14px;
  }
}
