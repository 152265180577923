/* Explore container */
.explore-container {
  width: 100%;
  max-width: 800px;
  margin: 24px auto 0;
  padding: 0 12px 16px;
}

/* Explore search section */
.explore-search-section {
  margin-bottom: 16px;
}

.search-filter-container {
  display: flex;
  width: 100%;
}

.explore-search {
  flex: 1;
}

/* Filters */
.explore-filters {
  margin-bottom: 20px;
  padding: 16px;
  background: #f8faff;
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(76, 111, 255, 0.05);
  border: 1px solid #e6ecff;
}

.filter-section {
  margin-bottom: 16px;
}

.filter-section:last-child {
  margin-bottom: 0;
}

.filter-tags {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.filter-active {
  background-color: #e6f7ff;
  border-color: #1890ff;
  color: #1890ff;
}

/* Creator tags */
.creator-tag {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 12px;
  margin-right: 4px;
}

.creator-official {
  background-color: #e6f7ff;
  color: #1890ff;
}

.creator-friend {
  background-color: #f6ffed;
  color: #52c41a;
}

.creator-public {
  background-color: #f9f9f9;
  color: #8c8c8c;
}

/* Explore cards */
.explore-card {
  height: 100%;
  margin-bottom: 0;
  border-radius: 16px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 10px rgba(76, 111, 255, 0.07);
  border: 1px solid #edf0ff;
  display: flex;
  flex-direction: column;
}

.explore-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(76, 111, 255, 0.12);
  border-color: #dce4ff;
}

.explore-card-content {
  display: flex;
  padding: 16px;
  flex: 1;
}

.explore-card-image {
  width: 70px;
  height: 70px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 16px;
  background: #f5f8ff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.explore-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.explore-card-details {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.explore-card-meta {
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.explore-card-categories {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.explore-card-actions {
  padding: 0 16px 16px;
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
}

/* Add custom button */
.add-custom-button {
  display: flex;
  justify-content: center;
  margin: 24px 0;
}

/* Previously viewed */
.previously-viewed {
  margin-bottom: 20px;
}

.horizontal-scroll {
  display: flex;
  overflow-x: auto;
  padding: 10px 0;
  gap: 12px;
  scrollbar-width: thin;
  scrollbar-color: #bfdbfe #f0f7ff;
}

.horizontal-scroll::-webkit-scrollbar {
  height: 6px;
}

.horizontal-scroll::-webkit-scrollbar-track {
  background: #f0f7ff;
  border-radius: 10px;
}

.horizontal-scroll::-webkit-scrollbar-thumb {
  background-color: #bfdbfe;
  border-radius: 10px;
}

.mini-product-card {
  min-width: 120px;
  width: 120px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 6px rgba(76, 111, 255, 0.06);
  background: white;
  border: 1px solid #edf0ff;
  margin-bottom: 0;
}

.mini-product-card img {
  width: 100%;
  height: 80px;
  object-fit: cover;
  border-bottom: 1px solid #f0f5ff;
}

.mini-product-card .ant-typography {
  padding: 6px 8px;
  margin: 0;
  font-size: 12px;
  text-align: center;
}

/* Current needs tags */
.current-needs-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

/* Tabs */
.explore-tabs {
  margin-top: 16px;
}

.explore-tabs .ant-tabs-nav {
  margin-bottom: 16px;
}

.explore-tabs .ant-tabs-tab {
  padding: 8px 16px;
  font-size: 14px;
}

.explore-tabs .ant-tabs-ink-bar {
  background-color: #5c9dff;
}

/* Fix journey phase badge */
.baby-journey-phase .journey-phase-badge {
  white-space: nowrap;
}

/* Fix List grid spacing */
.ant-list-grid .ant-list-item {
  margin-bottom: 16px;
}

/* Mobile responsive */
@media (max-width: 768px) {
  .explore-card-content {
    flex-direction: column;
  }
  
  .explore-card-image {
    width: 100%;
    height: 160px;
    margin-right: 0;
    margin-bottom: 12px;
  }
  
  .explore-search-section .plan-title-section {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .explore-search-section .plan-title-section > div:last-child {
    margin-top: 12px;
    width: 100%;
  }
  
  .search-filter-container {
    width: 100%;
  }
  
  .explore-search {
    flex: 1;
  }
}
