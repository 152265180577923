.chat-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 120px);
  max-width: 1000px;
  margin: 0 auto;
  padding: 16px;
  padding-bottom: 180px; /* Reduced space for input, tabs and navigation */
}

.chat-header {
  text-align: center;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
}

.chat-header h2 {
  margin-bottom: 0;
  font-size: 22px;
}

.chat-tabs {
  margin-top: 16px;
}

.chat-tabs .ant-tabs-nav {
  margin-bottom: 0;
}

.chat-tabs-container {
  margin-top: 0;
  position: fixed;
  bottom: 64px; /* Positioned directly above navigation */
  left: 0;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.08);
  z-index: 1001; /* Higher than navigation to ensure visibility */
  padding: 4px 0;
  width: 100%;
  min-height: 56px; /* Minimum height for compact tabs */
  max-height: 64px; /* Maximum height */
  height: auto; /* Allow height to adapt */
  display: block !important; /* Force display */
}

.bottom-tabs {
  margin-top: 0;
  height: 100%;
  display: block !important; /* Force display */
}

.bottom-tabs .ant-tabs-nav {
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
  height: 100%;
}

.bottom-tabs .ant-tabs-nav-list {
  width: 100%;
  display: flex !important; /* Force display flex */
  justify-content: space-between !important;
  height: 100%;
  align-items: center !important;
}

.bottom-tabs .ant-tabs-tab {
  flex: 1;
  display: block !important; /* Force block display for reliable centering */
  margin: 0 !important;
  padding: 8px 0 6px 0 !important;
  min-width: 80px;
  text-align: center;
  height: 56px !important;
  max-height: 56px !important;
  background-color: white !important;
  opacity: 1 !important;
  overflow: visible;
}

.bottom-tabs .ant-tabs-tab div.tab-content {
  height: 100% !important;
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}

.tab-content {
  display: block !important;
  height: 100%;
  width: 100%;
  color: #666;
  font-size: 12px;
  padding: 0;
  line-height: 1.2;
  position: relative;
  text-align: center;
  margin: 0 auto;
}

.tab-content .anticon {
  font-size: 18px !important;
  margin-bottom: 3px !important;
  width: 18px !important;
  height: 18px !important;
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  position: relative;
  line-height: 0 !important;
  overflow: visible;
}

.tab-content div {
  width: 100%;
  text-align: center;
  font-size: 11px;
  padding-top: 1px;
  line-height: 1;
  display: block;
}

.bottom-tabs .ant-tabs-tab-active .tab-content {
  color: #6B4EFF;
}

/* Active tab styles for different chat types */
.product-expert-chat .bottom-tabs .ant-tabs-tab-active .tab-content {
  color: #6B4EFF;
}

.therapist-chat .bottom-tabs .ant-tabs-tab-active .tab-content {
  color: #ff69b4;
}

.medical-chat .bottom-tabs .ant-tabs-tab-active .tab-content {
  color: #ff7f50;
}

.financial-chat .bottom-tabs .ant-tabs-tab-active .tab-content {
  color: #52c41a;
}

.bottom-tabs .ant-tabs-ink-bar {
  display: none; /* Hide the underline as it can be buggy with fixed positioning */
}

.chat-type-description {
  margin-bottom: 8px;
  padding: 0;
  font-size: 12px;
}

.chat-type-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

/* Removed colorful borders */

/* Removed chat tags */
.chat-type-info .ant-typography {
  font-size: 13px;
  line-height: 1.5;
}

/* Horizontal suggested questions */
.suggested-questions-horizontal {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.suggested-questions-header {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #666;
  margin-bottom: 5px;
  cursor: pointer;
  font-weight: 500;
}

.suggested-questions-header:hover {
  color: #555;
}

.horizontal-questions {
  display: flex;
  padding-bottom: 4px;
  height: 40px;
  max-width: 80%;
}

/* Rotating questions */
.rotating-questions {
  width: auto;
  min-width: 300px;
  max-width: 500px;
  position: relative;
  height: 42px;
  cursor: pointer;
  overflow: hidden;
  background-color: transparent;
  border-radius: 6px;
  padding: 8px 10px;
  border: none;
  display: inline-block;
}

.question-display {
  position: relative;
  height: 100%;
  width: 100%;
}

.single-question {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #3a3a3a;
  padding: 0 8px;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  text-align: center;
  font-style: italic;
}

.single-question.fade-out {
  opacity: 0;
}

.rotating-questions:hover {
  text-decoration: underline;
  color: #333;
}

/* Modal popup styling */
.suggestion-popup-item {
  transition: all 0.2s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.03) !important;
}

.suggestion-popup-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08) !important;
  border-color: #d9d9d9 !important;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 8px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  min-height: 50vh; /* Increased for better message display */
  max-height: calc(100vh - 300px); /* Increased to allow more visible messages */
}

.message-item {
  margin-bottom: 20px !important; /* Increased from 16px for better spacing */
  animation: fadeIn 0.3s ease-out;
  display: flex !important;
  width: 100% !important;
  padding: 0 !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.message-item.assistant {
  justify-content: flex-start;
}

.message-item.user {
  justify-content: flex-end !important;
  flex-direction: row-reverse !important;
  text-align: right;
}

.message-content {
  display: flex;
  max-width: 75%;
}

.message-item.user .message-content {
  flex-direction: row-reverse !important;
  margin-left: auto !important;
  margin-right: 0 !important;
}

.message-avatar {
  margin: 0 8px;
  display: flex;
  align-items: center;
}

.message-item.user .message-avatar {
  margin-left: 8px;
  margin-right: 0;
}

.message-item.assistant .message-avatar {
  margin-left: 0;
  margin-right: 8px;
}

.message-bubble {
  background-color: #fff;
  border-radius: 12px;
  padding: 12px 16px;  /* Increased horizontal padding */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
}

.message-item.assistant .message-bubble {
  border-top-left-radius: 4px;
}

.message-item.user .message-bubble {
  background-color: #e6f7ff;
  border-top-right-radius: 4px;
}

/* Product expert styling */
.product-expert-chat .message-item.assistant .message-bubble {
  background-color: #f0f2ff;
}

/* Therapist styling */
.therapist-chat .message-item.assistant .message-bubble {
  background-color: #fff0f7;
}

/* Medical assistant styling */
.medical-chat .message-item.assistant .message-bubble {
  background-color: #fff3e8;
}

/* Financial advisor styling */
.financial-chat .message-item.assistant .message-bubble {
  background-color: #f6ffed;
}

/* Override specifically for Budget bot */
.financial-chat .markdown-content p,
.financial-chat .markdown-content > div > p {
  margin: 0 0 4px 0 !important;
  line-height: 1.4 !important;
}

.financial-chat .markdown-content ul,
.financial-chat .markdown-content ol,
.financial-chat .markdown-content > div > ul,
.financial-chat .markdown-content > div > ol {
  padding-left: 20px !important;
  margin: 2px 0 4px 0 !important;
}

.financial-chat .markdown-content li,
.financial-chat .markdown-content > div > li,
.financial-chat .markdown-content > div > ul li,
.financial-chat .markdown-content > div > ol li {
  margin-bottom: 2px !important;
  padding-left: 0 !important;
  line-height: 1.3 !important;
}

.financial-chat .markdown-content h1,
.financial-chat .markdown-content h2,
.financial-chat .markdown-content h3,
.financial-chat .markdown-content h4,
.financial-chat .markdown-content h5,
.financial-chat .markdown-content h6,
.financial-chat .markdown-content > div > h1,
.financial-chat .markdown-content > div > h2,
.financial-chat .markdown-content > div > h3,
.financial-chat .markdown-content > div > h4,
.financial-chat .markdown-content > div > h5,
.financial-chat .markdown-content > div > h6 {
  margin-top: 10px !important;
  margin-bottom: 2px !important;
  line-height: 1.3 !important;
}

/* Global chat markdown overrides with maximum specificity */
.chat-container .message-bubble .markdown-content p,
.chat-container .message-bubble .markdown-content > div > p,
.product-expert-chat .message-bubble .markdown-content p,
.product-expert-chat .message-bubble .markdown-content > div > p,
.therapist-chat .message-bubble .markdown-content p,
.therapist-chat .message-bubble .markdown-content > div > p,
.medical-chat .message-bubble .markdown-content p,
.medical-chat .message-bubble .markdown-content > div > p,
.financial-chat .message-bubble .markdown-content p,
.financial-chat .message-bubble .markdown-content > div > p {
  margin: 0 0 4px 0 !important;
  line-height: 1.4 !important;
  font-size: 14px !important;
}

.chat-container .message-bubble .markdown-content p:last-child,
.chat-container .message-bubble .markdown-content > div > p:last-child,
.product-expert-chat .message-bubble .markdown-content p:last-child,
.product-expert-chat .message-bubble .markdown-content > div > p:last-child,
.therapist-chat .message-bubble .markdown-content p:last-child,
.therapist-chat .message-bubble .markdown-content > div > p:last-child,
.medical-chat .message-bubble .markdown-content p:last-child,
.medical-chat .message-bubble .markdown-content > div > p:last-child,
.financial-chat .message-bubble .markdown-content p:last-child,
.financial-chat .message-bubble .markdown-content > div > p:last-child {
  margin-bottom: 0 !important;
}

.chat-container .message-bubble .markdown-content ul,
.chat-container .message-bubble .markdown-content ol,
.chat-container .message-bubble .markdown-content > div > ul,
.chat-container .message-bubble .markdown-content > div > ol,
.product-expert-chat .message-bubble .markdown-content ul,
.product-expert-chat .message-bubble .markdown-content ol,
.product-expert-chat .message-bubble .markdown-content > div > ul,
.product-expert-chat .message-bubble .markdown-content > div > ol,
.therapist-chat .message-bubble .markdown-content ul,
.therapist-chat .message-bubble .markdown-content ol,
.therapist-chat .message-bubble .markdown-content > div > ul,
.therapist-chat .message-bubble .markdown-content > div > ol,
.medical-chat .message-bubble .markdown-content ul,
.medical-chat .message-bubble .markdown-content ol,
.medical-chat .message-bubble .markdown-content > div > ul,
.medical-chat .message-bubble .markdown-content > div > ol,
.financial-chat .message-bubble .markdown-content ul,
.financial-chat .message-bubble .markdown-content ol,
.financial-chat .message-bubble .markdown-content > div > ul,
.financial-chat .message-bubble .markdown-content > div > ol {
  padding-left: 20px !important;
  margin: 2px 0 4px 0 !important;
}

.chat-container .message-bubble .markdown-content li,
.chat-container .message-bubble .markdown-content > div > li,
.chat-container .message-bubble .markdown-content > div > ul li,
.chat-container .message-bubble .markdown-content > div > ol li,
.product-expert-chat .message-bubble .markdown-content li,
.product-expert-chat .message-bubble .markdown-content > div > li,
.product-expert-chat .message-bubble .markdown-content > div > ul li,
.product-expert-chat .message-bubble .markdown-content > div > ol li,
.therapist-chat .message-bubble .markdown-content li,
.therapist-chat .message-bubble .markdown-content > div > li,
.therapist-chat .message-bubble .markdown-content > div > ul li,
.therapist-chat .message-bubble .markdown-content > div > ol li,
.medical-chat .message-bubble .markdown-content li,
.medical-chat .message-bubble .markdown-content > div > li,
.medical-chat .message-bubble .markdown-content > div > ul li,
.medical-chat .message-bubble .markdown-content > div > ol li,
.financial-chat .message-bubble .markdown-content li,
.financial-chat .message-bubble .markdown-content > div > li,
.financial-chat .message-bubble .markdown-content > div > ul li,
.financial-chat .message-bubble .markdown-content > div > ol li {
  margin-bottom: 2px !important;
  padding-left: 0 !important;
  line-height: 1.3 !important;
}

.chat-container .message-bubble .markdown-content h1,
.chat-container .message-bubble .markdown-content h2,
.chat-container .message-bubble .markdown-content h3,
.chat-container .message-bubble .markdown-content h4,
.chat-container .message-bubble .markdown-content h5,
.chat-container .message-bubble .markdown-content h6,
.chat-container .message-bubble .markdown-content > div > h1,
.chat-container .message-bubble .markdown-content > div > h2,
.chat-container .message-bubble .markdown-content > div > h3,
.chat-container .message-bubble .markdown-content > div > h4,
.chat-container .message-bubble .markdown-content > div > h5,
.chat-container .message-bubble .markdown-content > div > h6,
.product-expert-chat .message-bubble .markdown-content h1,
.product-expert-chat .message-bubble .markdown-content h2,
.product-expert-chat .message-bubble .markdown-content h3,
.product-expert-chat .message-bubble .markdown-content h4,
.product-expert-chat .message-bubble .markdown-content h5,
.product-expert-chat .message-bubble .markdown-content h6,
.product-expert-chat .message-bubble .markdown-content > div > h1,
.product-expert-chat .message-bubble .markdown-content > div > h2,
.product-expert-chat .message-bubble .markdown-content > div > h3,
.product-expert-chat .message-bubble .markdown-content > div > h4,
.product-expert-chat .message-bubble .markdown-content > div > h5,
.product-expert-chat .message-bubble .markdown-content > div > h6,
.therapist-chat .message-bubble .markdown-content h1,
.therapist-chat .message-bubble .markdown-content h2,
.therapist-chat .message-bubble .markdown-content h3,
.therapist-chat .message-bubble .markdown-content h4,
.therapist-chat .message-bubble .markdown-content h5,
.therapist-chat .message-bubble .markdown-content h6,
.therapist-chat .message-bubble .markdown-content > div > h1,
.therapist-chat .message-bubble .markdown-content > div > h2,
.therapist-chat .message-bubble .markdown-content > div > h3,
.therapist-chat .message-bubble .markdown-content > div > h4,
.therapist-chat .message-bubble .markdown-content > div > h5,
.therapist-chat .message-bubble .markdown-content > div > h6,
.medical-chat .message-bubble .markdown-content h1,
.medical-chat .message-bubble .markdown-content h2,
.medical-chat .message-bubble .markdown-content h3,
.medical-chat .message-bubble .markdown-content h4,
.medical-chat .message-bubble .markdown-content h5,
.medical-chat .message-bubble .markdown-content h6,
.medical-chat .message-bubble .markdown-content > div > h1,
.medical-chat .message-bubble .markdown-content > div > h2,
.medical-chat .message-bubble .markdown-content > div > h3,
.medical-chat .message-bubble .markdown-content > div > h4,
.medical-chat .message-bubble .markdown-content > div > h5,
.medical-chat .message-bubble .markdown-content > div > h6,
.financial-chat .message-bubble .markdown-content h1,
.financial-chat .message-bubble .markdown-content h2,
.financial-chat .message-bubble .markdown-content h3,
.financial-chat .message-bubble .markdown-content h4,
.financial-chat .message-bubble .markdown-content h5,
.financial-chat .message-bubble .markdown-content h6,
.financial-chat .message-bubble .markdown-content > div > h1,
.financial-chat .message-bubble .markdown-content > div > h2,
.financial-chat .message-bubble .markdown-content > div > h3,
.financial-chat .message-bubble .markdown-content > div > h4,
.financial-chat .message-bubble .markdown-content > div > h5,
.financial-chat .message-bubble .markdown-content > div > h6 {
  margin-top: 10px !important;
  margin-bottom: 2px !important;
  line-height: 1.3 !important;
  padding-bottom: 0 !important;
}

/* Override specific for empty paragraphs */
.chat-container .message-bubble .markdown-content > div > p:empty,
.product-expert-chat .message-bubble .markdown-content > div > p:empty,
.therapist-chat .message-bubble .markdown-content > div > p:empty,
.medical-chat .message-bubble .markdown-content > div > p:empty,
.financial-chat .message-bubble .markdown-content > div > p:empty {
  margin: 2px 0 !important;
  height: 2px !important;
  display: block !important;
}

/* Override for consecutive empty elements */
.chat-container .message-bubble .markdown-content > div > p:empty + p:empty,
.chat-container .message-bubble .markdown-content > div > br + br,
.product-expert-chat .message-bubble .markdown-content > div > p:empty + p:empty,
.product-expert-chat .message-bubble .markdown-content > div > br + br,
.therapist-chat .message-bubble .markdown-content > div > p:empty + p:empty,
.therapist-chat .message-bubble .markdown-content > div > br + br,
.medical-chat .message-bubble .markdown-content > div > p:empty + p:empty,
.medical-chat .message-bubble .markdown-content > div > br + br,
.financial-chat .message-bubble .markdown-content > div > p:empty + p:empty,
.financial-chat .message-bubble .markdown-content > div > br + br {
  display: none !important;
}

/* Target specific issue with numbered lists and spacing */
.message-bubble .markdown-content ol,
.message-bubble .markdown-content > div > ol,
.message-bubble .markdown-content ul,
.message-bubble .markdown-content > div > ul {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.message-bubble .markdown-content ol > li,
.message-bubble .markdown-content > div > ol > li,
.message-bubble .markdown-content ul > li,
.message-bubble .markdown-content > div > ul > li {
  margin-top: 0 !important;
  margin-bottom: 2px !important;
  padding: 0 !important;
}

.message-bubble .markdown-content ol > li > ol,
.message-bubble .markdown-content > div > ol > li > ol,
.message-bubble .markdown-content ul > li > ul,
.message-bubble .markdown-content > div > ul > li > ul,
.message-bubble .markdown-content ol > li > ul,
.message-bubble .markdown-content > div > ol > li > ul,
.message-bubble .markdown-content ul > li > ol,
.message-bubble .markdown-content > div > ul > li > ol {
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  padding-left: 16px !important;
}

.message-bubble .markdown-content p + ol,
.message-bubble .markdown-content > div > p + ol,
.message-bubble .markdown-content p + ul,
.message-bubble .markdown-content > div > p + ul {
  margin-top: 2px !important;
}

/* Remove all extra spaces in therapist/support chat */
.therapist-chat .message-bubble .markdown-content > div > p,
.therapist-chat .message-bubble .markdown-content p {
  margin-top: 0 !important;
  margin-bottom: 2px !important;
}

/* Extremely aggressive removal of spacing in therapist chat - target the specific pattern in the example */
.therapist-chat .message-bubble .markdown-content ol > li > p + p:empty,
.therapist-chat .message-bubble .markdown-content > div > ol > li > p + p:empty,
.therapist-chat .message-bubble .markdown-content > div > ol > li > p:empty,
.therapist-chat .message-bubble .markdown-content p:empty + p:empty {
  display: none !important;
  margin: 0 !important;
  height: 0 !important;
  padding: 0 !important;
}

/* Handle single empty paragraphs too */
.therapist-chat .message-bubble .markdown-content > div > p:empty {
  margin: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  display: none !important;
}

/* Fix specific issue with named list items in therapist chat */
.therapist-chat .message-bubble .markdown-content ol > li > p:first-child strong,
.therapist-chat .message-bubble .markdown-content > div > ol > li > p:first-child strong {
  display: inline-block !important;
  margin-bottom: 0 !important;
}

/* No spacing between lists and their content in therapist chat */
.therapist-chat .message-bubble .markdown-content ol li ol,
.therapist-chat .message-bubble .markdown-content ol li ul,
.therapist-chat .message-bubble .markdown-content > div > ol li ol,
.therapist-chat .message-bubble .markdown-content > div > ol li ul {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

/* Fix spacing between headings and lists across all chat types */
.chat-container .message-bubble .markdown-content h1 + ul,
.chat-container .message-bubble .markdown-content h2 + ul,
.chat-container .message-bubble .markdown-content h3 + ul,
.chat-container .message-bubble .markdown-content h1 + ol,
.chat-container .message-bubble .markdown-content h2 + ol,
.chat-container .message-bubble .markdown-content h3 + ol,
.chat-container .message-bubble .markdown-content > div > h1 + ul,
.chat-container .message-bubble .markdown-content > div > h2 + ul,
.chat-container .message-bubble .markdown-content > div > h3 + ul,
.chat-container .message-bubble .markdown-content > div > h1 + ol,
.chat-container .message-bubble .markdown-content > div > h2 + ol,
.chat-container .message-bubble .markdown-content > div > h3 + ol,
.product-expert-chat .message-bubble .markdown-content h1 + ul,
.product-expert-chat .message-bubble .markdown-content h2 + ul,
.product-expert-chat .message-bubble .markdown-content h3 + ul,
.product-expert-chat .message-bubble .markdown-content h1 + ol,
.product-expert-chat .message-bubble .markdown-content h2 + ol,
.product-expert-chat .message-bubble .markdown-content h3 + ol,
.product-expert-chat .message-bubble .markdown-content > div > h1 + ul,
.product-expert-chat .message-bubble .markdown-content > div > h2 + ul,
.product-expert-chat .message-bubble .markdown-content > div > h3 + ul,
.product-expert-chat .message-bubble .markdown-content > div > h1 + ol,
.product-expert-chat .message-bubble .markdown-content > div > h2 + ol,
.product-expert-chat .message-bubble .markdown-content > div > h3 + ol,
.therapist-chat .message-bubble .markdown-content h1 + ul,
.therapist-chat .message-bubble .markdown-content h2 + ul,
.therapist-chat .message-bubble .markdown-content h3 + ul,
.therapist-chat .message-bubble .markdown-content h1 + ol,
.therapist-chat .message-bubble .markdown-content h2 + ol,
.therapist-chat .message-bubble .markdown-content h3 + ol,
.therapist-chat .message-bubble .markdown-content > div > h1 + ul,
.therapist-chat .message-bubble .markdown-content > div > h2 + ul,
.therapist-chat .message-bubble .markdown-content > div > h3 + ul,
.therapist-chat .message-bubble .markdown-content > div > h1 + ol,
.therapist-chat .message-bubble .markdown-content > div > h2 + ol,
.therapist-chat .message-bubble .markdown-content > div > h3 + ol,
.medical-chat .message-bubble .markdown-content h1 + ul,
.medical-chat .message-bubble .markdown-content h2 + ul,
.medical-chat .message-bubble .markdown-content h3 + ul,
.medical-chat .message-bubble .markdown-content h1 + ol,
.medical-chat .message-bubble .markdown-content h2 + ol,
.medical-chat .message-bubble .markdown-content h3 + ol,
.medical-chat .message-bubble .markdown-content > div > h1 + ul,
.medical-chat .message-bubble .markdown-content > div > h2 + ul,
.medical-chat .message-bubble .markdown-content > div > h3 + ul,
.medical-chat .message-bubble .markdown-content > div > h1 + ol,
.medical-chat .message-bubble .markdown-content > div > h2 + ol,
.medical-chat .message-bubble .markdown-content > div > h3 + ol,
.financial-chat .message-bubble .markdown-content h1 + ul,
.financial-chat .message-bubble .markdown-content h2 + ul,
.financial-chat .message-bubble .markdown-content h3 + ul,
.financial-chat .message-bubble .markdown-content h1 + ol,
.financial-chat .message-bubble .markdown-content h2 + ol,
.financial-chat .message-bubble .markdown-content h3 + ol,
.financial-chat .message-bubble .markdown-content > div > h1 + ul,
.financial-chat .message-bubble .markdown-content > div > h2 + ul,
.financial-chat .message-bubble .markdown-content > div > h3 + ul,
.financial-chat .message-bubble .markdown-content > div > h1 + ol,
.financial-chat .message-bubble .markdown-content > div > h2 + ol,
.financial-chat .message-bubble .markdown-content > div > h3 + ol {
  margin-top: 0 !important;
  margin-bottom: 4px !important;
}

.message-text {
  white-space: pre-wrap;
  word-break: break-word;
}

.message-text p {
  margin-bottom: 8px;
}

.message-text p:last-child {
  margin-bottom: 0;
}

/* Markdown styling */
.message-bubble .markdown-content,
.product-expert-chat .markdown-content,
.therapist-chat .markdown-content,
.medical-chat .markdown-content,
.financial-chat .markdown-content {
  font-size: 14px;
  line-height: 1.5;
}

.markdown-content > div {
  display: block;
  width: 100%;
}

.markdown-content > div > *:first-child {
  margin-top: 0;
}

/* Fix extra spacing with blank lines */
.markdown-content br + br {
  display: none; /* Remove consecutive line breaks */
}

/* Add space between different elements */
.markdown-content > div > h1 + p,
.markdown-content > div > h2 + p,
.markdown-content > div > h3 + p,
.markdown-content > div > h4 + p {
  margin-top: 8px;
}

/* Proper spacing for structured content with colon notation */
.markdown-content strong + br,
.markdown-content > div > strong + br {
  display: none; /* Remove line break after label in colon notation */
}

.markdown-content strong:first-child,
.markdown-content > div > p > strong:first-child {
  display: inline-block;
  margin-right: 4px;
}

/* Special handling for sections with double line breaks */
.markdown-content > div > p:empty + p:empty,
.markdown-content > div > br + br,
.markdown-content > div > p:empty + br,
.markdown-content > div > br + p:empty {
  display: none; /* Remove all consecutive empty elements */
}

.markdown-content > div > p:empty {
  margin: 2px 0;
  height: 2px;
  display: block;
}

.markdown-content > div > p:empty + h1,
.markdown-content > div > p:empty + h2,
.markdown-content > div > p:empty + h3 {
  margin-top: 6px; /* Minimal space before headings after empty line */
}

/* Add spacing after sections */
.markdown-content > div > h2,
.markdown-content > div > h3 {
  margin-top: 8px;
  margin-bottom: 2px;
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6,
.markdown-content > div > h1,
.markdown-content > div > h2,
.markdown-content > div > h3,
.markdown-content > div > h4,
.markdown-content > div > h5,
.markdown-content > div > h6 {
  margin-top: 10px;
  margin-bottom: 2px;
  font-weight: 500;
  line-height: 1.3;
  padding-bottom: 0;
}

.markdown-content h1,
.markdown-content > div > h1 {
  font-size: 20px;
}

.markdown-content h2,
.markdown-content > div > h2 {
  font-size: 18px;
}

.markdown-content h3,
.markdown-content > div > h3 {
  font-size: 16px;
}

.markdown-content h4,
.markdown-content h5,
.markdown-content h6,
.markdown-content > div > h4,
.markdown-content > div > h5,
.markdown-content > div > h6 {
  font-size: 14px;
}

.markdown-content ul,
.markdown-content ol,
.markdown-content > div > ul,
.markdown-content > div > ol {
  padding-left: 20px;
  margin: 2px 0 4px 0; /* Minimal margins */
}

.markdown-content li,
.markdown-content > div > li,
.markdown-content > div > ul li,
.markdown-content > div > ol li {
  margin-bottom: 2px;  /* Minimal margin */
  padding-left: 0;
  line-height: 1.3;
}

.markdown-content li:last-child,
.markdown-content > div > li:last-child,
.markdown-content > div > ul li:last-child,
.markdown-content > div > ol li:last-child {
  margin-bottom: 0;
}

/* Add spacing before the first item if preceded by a heading */
.markdown-content h1 + ul,
.markdown-content h2 + ul,
.markdown-content h3 + ul,
.markdown-content h1 + ol,
.markdown-content h2 + ol,
.markdown-content h3 + ol,
.markdown-content > div > h1 + ul,
.markdown-content > div > h2 + ul,
.markdown-content > div > h3 + ul,
.markdown-content > div > h1 + ol,
.markdown-content > div > h2 + ol,
.markdown-content > div > h3 + ol {
  margin-top: 0;
  margin-bottom: 6px;
}

/* Adjust nested lists */
.markdown-content li > ul,
.markdown-content li > ol,
.markdown-content > div > li > ul,
.markdown-content > div > li > ol {
  margin-top: 2px;
  margin-bottom: 0;
  padding-left: 16px; /* Minimized padding for nested lists */
}

.markdown-content code,
.markdown-content > div code {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 2px 4px;
  border-radius: 3px;
  font-family: monospace;
}

.markdown-content pre,
.markdown-content > div pre {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 12px;
  border-radius: 4px;
  overflow-x: auto;
  margin: 12px 0;
  font-size: 13px;
  white-space: pre-wrap;
}

.markdown-content blockquote,
.markdown-content > div blockquote {
  border-left: 4px solid #ddd;
  padding-left: 12px;
  color: #666;
  margin: 8px 0;
}

.markdown-content a,
.markdown-content > div a {
  color: #1890ff;
  text-decoration: none;
}

.markdown-content a:hover,
.markdown-content > div a:hover {
  text-decoration: underline;
}

.markdown-content table,
.markdown-content > div table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 16px;
}

.markdown-content table th,
.markdown-content table td,
.markdown-content > div table th,
.markdown-content > div table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.markdown-content table th,
.markdown-content > div table th {
  background-color: rgba(0, 0, 0, 0.05);
  text-align: left;
}

/* Additional paragraph styling */
.markdown-content p,
.markdown-content > div > p {
  margin: 0 0 4px 0; /* Minimal bottom margin */
  line-height: 1.4;
}

.markdown-content p:last-child,
.markdown-content > div > p:last-child {
  margin-bottom: 0;
}

/* Improve paragraph spacing and readability for structured content */
.markdown-content p + ul,
.markdown-content p + ol,
.markdown-content > div > p + ul,
.markdown-content > div > p + ol {
  margin-top: 0;  /* No negative margin */
}

/* Fix spacing for empty lines - removing this as it's duplicated elsewhere */

.message-time {
  font-size: 12px;
  color: #999;
  text-align: right;
  margin-top: 4px;
}

.loading-indicator {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
  margin-left: 16px;
}

.suggested-questions {
  margin-top: 8px;
  margin-bottom: 0;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.suggested-header {
  padding: 6px 10px;
  background-color: #f5f5f5;
  font-size: 12px;
  color: #555;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

.suggested-header:hover {
  background-color: #e8e8e8;
}

.questions-container {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  padding: 6px;
  background-color: #fafafa;
}

.questions-container.expanded {
  max-height: 150px;
  overflow-y: auto;
}

.questions-container.collapsed {
  max-height: none;
  background-color: #fafafa;
}

.question-button {
  text-align: left;
  height: auto;
  padding: 4px 12px;
  white-space: normal;
  font-size: 13px;
  margin: 3px;
  border-color: #d9d9d9;
  background-color: #f7f7f7;
}

.question-button:hover {
  background-color: #e6f7ff;
  border-color: #91d5ff;
}

.chat-bottom-container {
  position: fixed;
  bottom: 134px; /* Above the tabs */
  left: 0;
  right: 0;
  z-index: 990;
  padding: 0 16px;
}

.chat-input {
  display: flex;
  gap: 8px;
  padding: 12px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 0;
}

.chat-input .ant-input {
  border-radius: 20px;
  padding: 8px 16px;
}

.chat-input .ant-btn {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

/* Mobile responsiveness */
@media screen and (max-width: 768px) {
  .chat-container {
    padding: 12px;
    height: calc(100vh - 120px);
    padding-bottom: 160px; /* Slightly less padding on mobile */
  }
  
  .message-content {
    max-width: 85%;
  }
  
  .chat-messages {
    min-height: 45vh;
    max-height: calc(100vh - 280px);
  }
  
  .chat-tabs-container {
    bottom: 56px; /* Adjust for smaller nav on mobile */
  }
  
  .chat-bottom-container {
    bottom: 120px; /* Adjust for smaller screen on mobile */
  }
}