/* Goals Component Styles */
.goals-container {
  padding: 20px;
  padding-bottom: 240px; /* Further increased space for the bottom navigation */
  max-width: 1200px;
  margin: 0 auto;
}

.goals-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.progress-overview {
  margin-bottom: 24px;
}

.progress-card {
  border-radius: 8px;
}

.progress-card .ant-statistic {
  margin-bottom: 8px;
}

.add-goal-button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.goals-list {
  margin-bottom: 40px;
}

.plan-title-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
}

.plan-title-section h4 {
  margin: 0;
  line-height: 32px; /* Match the height of the button */
}

.plan-title-section button {
  margin-top: 1px; /* Fine-tune alignment */
}

.suggested-goals-section {
  margin-top: 24px;
  margin-bottom: 120px; /* Increased bottom margin further */
  padding-top: 24px;
  padding-bottom: 50px; /* Added more bottom padding */
  border-top: 1px solid #f0f0f0;
}

.goal-card, .suggested-goal-card {
  width: 100%;
  margin-bottom: 16px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.goal-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.suggested-goal-card.accepted:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.goal-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.goal-info {
  flex: 1;
}

.goal-meta {
  margin-top: 12px;
}

.goal-modules {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-bottom: 8px;
}

.goal-stats {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.goal-stats-details {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 4px;
}

.goal-actions {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}

.goal-delete-button {
  color: #ff4d4f;
  font-size: 16px;
  cursor: pointer;
  padding: 4px;
  border-radius: 50%;
  transition: all 0.2s;
}

.goal-delete-button:hover {
  background-color: rgba(255, 77, 79, 0.1);
}

.goal-progress-container {
  height: 8px;
  background: #f0f0f0;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 15px;
}

.goal-progress-bar {
  height: 100%;
  border-radius: 4px;
}

.goal-progress-low {
  background: #faad14;
}

.goal-progress-medium {
  background: #1890ff;
}

.goal-progress-high {
  background: #52c41a;
}

.suggested-goal-actions {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.accept-button, .reject-button {
  min-width: 120px;
  display: flex;
  align-items: center;
}

.accept-button {
  white-space: nowrap;
}

.update-profile-link {
  display: block;
  margin-top: 4px;
  cursor: pointer;
  text-decoration: underline;
}

.empty-goals {
  text-align: center;
  padding: 40px 0;
}

.empty-goals-title {
  color: #999;
}

.empty-goals-description {
  color: #999;
}

/* Template selection styles */
.template-selection {
  margin-top: 16px;
}

.template-card {
  position: relative;
  transition: all 0.3s;
  height: 100%;
}

.template-card.selected {
  border-color: #1890ff;
  background-color: #e6f7ff;
}

.template-radio {
  position: absolute;
  top: 12px;
  right: 12px;
}

.template-content {
  padding-right: 24px;
}

.template-tags {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.build-from-scratch {
  text-align: center;
  margin-top: 24px;
}

/* Goal Details Styles */
.goal-details-container {
  padding: 20px;
  padding-bottom: 80px; /* Space for the bottom navigation */
  max-width: 1200px;
  margin: 0 auto;
}

.goal-details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.goal-details-card {
  margin-bottom: 24px;
}

.goal-details-main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;
  margin-bottom: 16px;
}

.goal-details-main > div:first-child {
  flex: 1;
  min-width: 300px;
}

.goal-modules {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
}

.goal-stats {
  width: 100%;
  max-width: 300px;
}

.progress-stats {
  margin-bottom: 16px;
}

.stats-counts {
  margin-top: 16px;
}

.stat-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.stat-count {
  font-size: 18px;
  font-weight: bold;
  color: #1890ff;
  min-width: 24px;
  text-align: center;
}

.stat-label {
  font-size: 14px;
}

.filter-controls {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 16px;
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.filter-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filter-section .ant-radio-group {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.goal-tabs {
  margin-top: 16px;
}

.items-by-module {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.goal-module-section {
  margin-bottom: 16px;
}

.goal-item-card {
  margin-bottom: 8px;
  transition: all 0.2s;
}

.goal-item-card:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.hidden-item {
  border-style: dashed;
}

.goal-item-content {
  display: flex;
  gap: 12px;
  align-items: flex-start;
  position: relative;
}

.goal-item-details {
  flex: 1;
}

.goal-item-name {
  font-weight: 500;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.shared-by-tag {
  margin-left: 8px;
  font-size: 12px;
}

.goal-item-meta {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 4px;
}

.goal-item-priority, .goal-item-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.goal-item-description {
  font-size: 12px;
}

.item-info-icon {
  color: #bbb;
  margin-top: 4px;
}

.suggested-item-card {
  background-color: #f5f5f5;
}

/* Item details modal */
.item-details-content {
  padding: 16px 0;
}

.item-detail-section {
  margin-bottom: 24px;
}

.requirement-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.item-detail-actions {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}

/* Mobile responsiveness */
@media screen and (max-width: 768px) {
  .goals-container, .goal-details-container {
    padding: 16px;
    padding-bottom: 260px; /* Additional padding on mobile */
  }
  
  .progress-overview .ant-row {
    margin-bottom: 8px;
  }
  
  .goal-content {
    flex-direction: column;
  }
  
  .goal-actions, .suggested-goal-actions {
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 12px;
  }
  
  .goal-stats {
    width: 100%;
    max-width: 100%;
  }
  
  .filter-controls {
    flex-direction: column;
    gap: 16px;
  }
  
  .filter-section {
    width: 100%;
  }
  
  .plan-title-section {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .plan-title-section h4 {
    margin: 0;
    margin-right: 10px;
    line-height: 32px;
  }
  
  .plan-title-section button {
    white-space: nowrap;
  }
}